<template>
	<div class="margin-t-20">
		<div v-loading="loading" :class="themeClass" class="width-fill bg-fff my-account">
			<div class="title font-size18 font-weight700">现金钱包银行卡</div>
			<div class="content flex-row-wrap">
				<div class="bottom  margin-t-15" v-for="(item,index) of rechargeACout" :key="index">
					<div class="card radius10">
						<div class="card-top thcls position-relative">
							<div class="bank flex-row">
								<div class="bank-icon flex-row-center-center">
									<svg-icon icon-class="UnionPay" />
								</div>
								<div class="bank-name font-size14  margin-r-5 font-weight700">{{item.fBankName}}</div>
								<div class="unbundling font-color-fff pointer" @click="unbundling(item.fAccountID)">解绑</div>
							</div>
							<div class="cardID font-color-fff font-size18 font-weight700" v-html="$options.filters.BankCardFormat(item.fAccountNumber)"></div>
							<div class="font-size12 margin-t-10 pointer" :class="{'font-color-fff':item.fIsDefault==0,'fonth':item.fIsDefault==1}"
							 @click="setMos(item.fAccountID)">{{item.fIsDefault==0?'设为默认':'默认'}}</div>
							<!-- <img class="bg-img" src="../../../assets/u298.png"></img> -->
						</div>
					</div>
				</div>
				<div class="bottom  margin-t-15 ">
					<div class="card radius10 u665">
						<div class="card-top font-weight700 colrm pointer" @click="openAccountPay()">+添加银行卡</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				loading: false,
				rechargeACout: [],
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(['getUserInfo']),
		},
		mounted() {
			this.getAcsPhysical();

		},
		methods: {
			//设为默认
			setMos(id) {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/set-cpcn-account-default', {
					fAccountID: id
				}).then(
					(result) => {
						this.getAcsPhysical();
					},
					(rej) => {}
				);
			},
			//解绑
			unbundling(id) {
				this.$confirm('是否要解绑银行卡?', '提示', {
					confirmButtonText: '解绑',
					cancelButtonText: '取消',
					type: 'info'
				}).then(() => {
					this.ApiRequestPost("api/mall/ebcustomer/bank-account/unbundling-cpcn-b2c-account", {
						fAccountID: id
					}).then(
						(result) => {
							this.getAcsPhysical();
						},
						(rej) => {}
					);
				}).catch(() => {});
			},
			//添加银行卡
			openAccountPay(val) {
				this.$router.push({
					path: '/businessme/individualUsersBindingCard',
					query: {
						title: '绑定银行卡',
						type: 1
					}
				}) //跳转个人用户绑卡
			},
			//获绑定银行卡列表
			getAcsPhysical(type) {
				this.loading = true;
				this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-cpcn-physical-account-list').then(
					(result) => {
						this.rechargeACout = [];
						let arrList = [];
						this.rechargeACout = result.obj;
						this.loading = false;
					},
					(rej) => {}
				);
			},
		},
		filters: {
			//格式化银行账户
			BankCardFormat: function(value) {
				// value = value.toString();
				if (value && value.length > 8) {
					return (`${value.substring(0, 4)}` + "<span style='margin:0px 4px;letter-spacing:3.5px'>" +
						`${"*".repeat(value.length - 8).replace(/(.{4})/g, `
						$1 `)}` + "</span>" + `${value.slice(-4)}`);
				}
				return value;
			},
			formatPhone: function(val) {
				return `${val.substring(0,3)}****${val.substring(val.length-3)}`
			}
		},
	}
</script>

<style lang="scss" scoped>
	.fonth {
		color: #FFFF33
	}

	.colrm {
		text-align: center;
		color: rgb(51, 51, 51) !important;
		margin-top: 10%;
	}

	.u665 {

		background: inherit;
		background-color: rgba(248, 249, 254, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(242, 242, 242, 1);

	}

	.thcls {
		// background: linear-gradient(135deg, rgba(0, 196, 186, 1) 0%, rgba(0, 196, 186, 1) 0%, rgba(0, 202, 213, 1) 52%, rgba(2, 223, 218, 1) 100%, rgba(2, 223, 218, 1) 100%);
		background-image: url('~@/assets/imgs/pay/xjczk.png');
		background-size: 100% 100%;
	}



	.content {

		// width: 440px;
		.title {
			height: 24px;
			line-height: 24px;
			margin-top: 8px;
			margin-left: 15px;
		}

		.top {
			height: 199px;

			.left {
				width: 160px;

				.btn-open {
					width: 160px;
					height: 40px;
					line-height: 40px;
					opacity: 1;
					background: #2e66f8;
					font-family: '阿里巴巴普惠体', '阿里巴巴普惠体'-400;
				}

				.num {
					height: 24px;
					line-height: 24px;
					opacity: 1;
				}

				.text {
					height: 17px;
					line-height: 17px;
				}
			}

			.right {
				height: 167px;
				flex: 1;

				.svgimage {
					width: 179px;
					height: 151px;
				}
			}
		}

		.bottom {

			// height: 230px;
			.card {
				width: 300px;
				height: 110px;
				opacity: 1;
				// background: #e1efff;
				overflow: hidden;
				margin-right: 8px;
				box-shadow: 0px 0px 10px rgba(1, 115, 254, 0.2);
			
				.card-top {
					// width: 405px;
					border-color: rgba(221, 235, 255, 1);
					color: #FFFFFF;
					height: 110px;
					opacity: 1;
					// background: #f8f9fe;
					box-sizing: border-box;
					padding: 15px;
					width: 100%;
					.bank {
						position: relative;
						height: 19px;
						line-height: 19px;

						.bank-icon {
							.svg-icon {
								width: 34px !important;
								height: 34px !important;
							}
						}

						.bank-name {
							margin-left: 5px;
						}
					}

					.cardID {
						height: 24px;
						margin-top: 12px;
					}
				}

				.userinfo {
					padding-left: 19px;
					padding-top: 14px;
				}

				.unbundling {
					margin-right: 21px;
					height: 16px;
					line-height: 16px;
					position: absolute;
					right: 0;
				}

			}
		}
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}
</style>
